@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import './GlobalDefine.css';

.documentPageContainer {
    max-width: 90%; /* Adjust the width as per your layout */
    padding: 20px;
    font-family: var(--mainFont);
    line-height: 1.5;
  }
  
  .login.logo-image {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: auto;
  }

  .welcomeMessage {
    margin-top: 10px;
    font-size: large;
    font-family: var(--mainFont);
  }

  .portal-text {
    font-size: xx-large;
    font-family: var(--mainFont);
    font-weight: bold;

  }

  .centerContent {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically if there's extra space */
    margin: 0 auto;
  }

  .appCenterContent {
    display: flex;
    flex-direction: row; /* Align children horizontally */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    margin: 0 auto;
}

.centeredImage {
    width: 100px;
    margin: 10px auto; /* Updated to auto for left and right margins */
    max-width: 100%; /* Ensures the image is responsive */
    height: auto; /* Maintains the aspect ratio of the image */
    display: block; /* Ensures the image is treated as a block-level element */
}
  .centeredImageStore {
    width: 200px;
    margin: 10px auto; /* Updated to auto for left and right margins */
    max-width: 100%; /* Ensures the image is responsive */
    height: auto; /* Maintains the aspect ratio of the image */
    display: block; /* Ensures the image is treated as a block-level element */
}
  .tableContainer {
    font-family: var(--mainFont);
    background-color: #f4f4f4;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer th,
  .tableContainer td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .tableContainer th {
    background-color: lightblue;
    font-family: var(--mainFont);
  }
  
  .tableContainer tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .tableContainer tr:hover {
    background-color: #ddd;
  }
  
  .whiteText {
    font-family: var(--mainFont);
  }
  
  .downloadLink {
    color: #007bff;
    text-decoration: none;
  }
  .downloadLink:hover {
    text-decoration: underline;
  }
  
  .row {
    display: flex;
    justify-content: center;
    text-align: center;
  }

.desc-container {
  font-family: var(--mainFont);
}

.desc-section {
  font-family: var(--mainFont);
}

.hljs-content {
  background-color: #2d2d2d;
  color: #f8f8f2;
  font-family: Consolas, "Courier New", monospace;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #444;
  overflow-x: auto;
  line-height: 1.5;
  font-size: 14px;
}

.floating-window {
  position: fixed;
  top: 140px;
  right: 20px;
  width: 70px;
  height: 70px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 1s ease;
  border-radius: 50%;
  overflow: hidden;
}

.floating-window:hover {
  transition: all 0.1s ease;
  transform: scale(1.1);
  background-color: #f0f0f0;
  border-radius: 8px;
  width: min-content;
  height: min-content;
}

.floating-window-header {
  display: none;
}

.floating-window::before {
  content: 'APIs';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.floating-window:hover .floating-window::before {
  content: 'APIs';
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.floating-window::before {
  content: 'APIs';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.floating-window:hover .floating-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f4f4f4;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  font-size: 16px;
  font-weight: bold;
}

.floating-window:hover::before {
  display: none;
}

.floating-window-content {
  font-size: 12px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 50px;
}

.close-button {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: red;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
}

.styled-table th, .styled-table td {
  border: 1px solid #ccc;
  padding: 8px 12px;
  text-align: left;
}

.styled-table thead {
  background-color: #e9ecef;
}

.styled-table tbody tr {
  background-color: #f9f9f9;
}

.styled-table tbody tr:hover {
  background-color: #ddd;
}
