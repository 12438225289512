@import './GlobalDefine.css';

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
@media(min-width: 600px) {
    :root {
        --header-height:60px
    }
}

@media(max-width: 767px) {
    :root {
        --content-h-padding:24px
    }
}

body,html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #353740;
    color: var(--text-default);
    font-family: Söhne,helvetica,sans-serif;
    font-family: var(--sans-serif);
    font-size: 16px;
    font-size: var(--font-size-small);
    height: 100%;
    margin: 0;
    padding: 0
}

*,:after,:before {
    box-sizing: border-box
}

/* For the style of the hyperlink*/
.link-style,a {
    color: #10a37f;
    cursor: pointer;
    text-decoration: none
}

.link-style:visited,a:visited {
    color: #10a37f
}

.link-style:hover,a:hover {
    color: #1a7f64
}

#root {
    height: 100%;
    overflow: auto
}

/* For main page */
.page-wrapper {
    box-sizing: border-box;
    color: #353740;
    color: var(--gray-800);
    display: flex;
    flex-direction: column;
    height: 100%
}

.route-container {
    height: 100%;
    position: relative;
    z-index: 1
}

@media print {
    #root {
        height: auto
    }

    .page-wrapper {
        height: auto;
        padding-top: 0
    }
}

/* For the whole web page contain navigation bar and content*/
.app-wrapper {
    --app-navigation-collapsed-width: 56px;
    --app-navigation-expanded-width: 200px;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.app-wrapper-max-height .app-wrapper {
    max-height: 100%
}

/* For navigation bar*/
.app-navigation {
    background: var(--bg-color);
    border-right: 1px solid var(--gray-100);
    box-shadow: 0 0 0 transparent;
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    transition: width .15s cubic-bezier(0,.57,.21,.99),box-shadow .15s cubic-bezier(0,.57,.21,.99);
    z-index: 101;
}

.app-navigation.static {
    min-width: var(--app-navigation-expanded-width);
    width: var(--app-navigation-expanded-width)
}

/* For navigation fix mode*/
.app-navigation.fixed {
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: calc(var(--app-navigation-collapsed-width) + 1px);
}

/* For navigation expanded mode*/
.app-navigation.fixed.expanded {
    box-shadow: 0 0 30px rgba(0,0,0,.2);
    width: var(--app-navigation-expanded-width)
}

@media(max-width: 599px) {
    .app-navigation {
        display:none
    }
}

/* For the header of navigation*/
.app-navigation-header {
    margin: 0 0 20px 2px
}

/* For the logo of navigation bar*/
.app-navigation-logo a,.app-navigation-logo a:visited {
    color: #000
}

/* For the items in navigation bar*/
.app-navigation-menu-item {
    align-items: center;
    border-radius: 8px;
    color: var(--gray-600);
    cursor: pointer;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    transition: all .15s ease-in-out
}

.app-navigation-menu-item:visited:not(:hover):not(.active) {
    color: var(--gray-600)
}

.app-navigation-menu-item:hover:not(.active) {
    background: var(--gray-50);
    color: var(--gray-800)
}

.app-navigation-menu-item.active {
    background-color: #e6f3f3;
    color: var(--primary);
    font-weight: 700
}

.app-navigation-menu-item+.app-navigation-menu-item {
    margin-top: 4px
}

.app-navigation-menu-item-icon {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    padding: 6px;
    width: 32px
}

.app-navigation-menu-item-icon svg {
    height: 20px;
    width: 20px
}

.app-navigation-menu-item-label {
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 20px;
    min-width: 0;
    overflow: hidden;
    padding: 6px;
    white-space: nowrap
}

.app-navigation-footer {
    margin-top: auto
}

.app-navigation-user-dropdown {
    border-top: 1px solid transparent;
    border-color: var(--gray-100);
    margin-top: 4px;
    overflow-x: hidden;
    padding-left: 6px;
    padding-top: 12px;
    transition: border-color .2s ease-in-out
}

.app-navigation-user-dropdown .user-section {
    min-width: 0;
    width: 100%
}

.app-navigation-user-dropdown .avatar {
    border: 1px solid var(--gray-200);
    flex: 0 0 auto;
    height: 20px;
    overflow: hidden;
    width: 20px
}

.app-navigation-user-dropdown .user-details {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.app-navigation.expanded .app-navigation-user-dropdown {
    border-color: var(--gray-100)
}

.app-main {
    flex: 1 1 auto;
    height: 100%;
    min-width: 0;
    overflow-y: auto
}

@media(max-width: 599px) {
    .app-main {
        padding-top:var(--header-height)
    }
}

@media(min-width: 600px) {
    .app-navigation.fixed+.app-main {
        padding-left:var(--app-navigation-collapsed-width)
    }
}

.app-page-content {
    max-width: 768px;
    padding: 24px
}

.app-page-content p {
    margin-top: 0
}

.app-page-content.flush {
    max-width: none
}

@media print {
    .app-main {
        padding-top: 0
    }
}

.pg-root {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    height: calc(100% - var(--header-height));
    position: relative
}

.pg-main {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column
}

.page-body {
    flex: 1 0 auto;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 100%;
    padding: 40px 56px;
    padding: var(--content-v-padding) var(--content-h-padding);
    width: 872px;
    width: calc(var(--content-width) + var(--content-h-padding)*2)
}

.page-body.full-width {
    width: 100%
}

.page-body.short-width {
    width: 670px
}

.page-body.medium-width {
    width: 900px
}

.page-body.large-width {
    width: 1040px
}

.page-body.flush {
    padding: 0
}

.layers-root {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

/* For the user avatar */
.avatar {
    align-items: center;
    background: var(--gray-800);
    border-radius: 100px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    width: 28px
}

.avatar>div {
    margin-top: -1px
}

.avatar img {
    border-radius: 100px
}

/* For the user description */
.user-section {
    align-items: center;
    cursor: pointer;
    display: inline-flex
}

.user-section svg {
    color: #6e6e80
}

.user-details {
    color: #565869;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
    text-align: right;
    text-decoration: none
}

.user-details-org {
    overflow: hidden;
    text-overflow: ellipsis
}

.user-dropdown {
    font-size: 14px;
    min-width: 200px;
    padding: 5px 0;
    text-align: left
}

.user-dropdown .user-name {
    color: #202123;
    font-weight: 700
}

.user-dropdown .user-email {
    color: #565869;
    font-size: 13px;
    font-weight: 400;
    margin-top: 3px
}

.user-dropdown .user-org-option {
    align-items: center;
    color: var(--gray-700);
    display: flex;
    justify-content: flex-start;
    padding-bottom: 4px;
    padding-top: 4px
}

.user-dropdown .user-org-option svg {
    flex: 0 0 auto;
    height: 18px;
    margin-left: 10px;
    opacity: 0;
    width: 18px
}

.user-dropdown .user-org-option.menu-item-selected {
    color: var(--primary)
}

.user-dropdown .user-org-option.menu-item-selected:not(:hover) {
    background: none
}

.user-dropdown .user-org-option.menu-item-selected svg {
    opacity: 1
}

.user-dropdown .user-dropdown-org-list {
    max-height: 405px;
    overflow-y: auto
}

.popover-body {
    background-color: #fff;
    border: 1px solid #edeef1;
    border-radius: 8px;
    box-shadow: 0 2px 20px rgba(5,5,5,.08);
    left: 0;
    position: absolute;
    top: 0;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    will-change: transform
}

@media(max-width: 600px) {
    .popover-body.popover-body-mobile-enabled {
        border-bottom:none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        bottom: 0;
        box-shadow: var(--popover-shadow-mobile);
        left: 0;
        margin: 0!important;
        position: fixed;
        right: 0;
        top: auto;
        -webkit-transform: none!important;
        transform: none!important
    }
}

/* For popup */
.popover-noninteractive {
    pointer-events: none
}

.popover-arrow {
    background: #fff;
    box-shadow: 0 2px 20px rgba(5,5,5,.08);
    opacity: 0;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.popover-arrow.visible {
    opacity: 1
}

.popover-inner {
    background: inherit;
    border-radius: 8px;
    position: relative
}

.popover-padding {
    padding: 12px
}

.menu-root {
    text-align: left
}

.menu-root .menu-item {
    box-sizing: border-box;
    color: var(--gray-900);
    font-size: 14px;
    padding: .4em 1em
}

.menu-root a.menu-item {
    color: var(--gray-900)!important;
    display: block;
    text-decoration: none
}

.menu-root .menu-item-selectable:hover,.menu-root .menu-item-selected {
    background: #e6f3f3;
    cursor: pointer
}

.menu-root .menu-item-selected {
    color: #10a37f;
    font-weight: 700
}

.menu-root .menu-item-disabled {
    color: var(--text-disabled);
    pointer-events: none
}

.menu-root .menu-separator {
    border-top: 1px solid var(--gray-100);
    margin: 5px 0
}

/* Margin and padding settings */
.mt-0\.5 {
    margin-top: 2px
}

.pt-0\.5 {
    padding-top: 2px
}

.mb-0\.5 {
    margin-bottom: 2px
}

.pb-0\.5 {
    padding-bottom: 2px
}

.ml-0\.5 {
    margin-left: 2px
}

.pl-0\.5 {
    padding-left: 2px
}

.mr-0\.5 {
    margin-right: 2px
}

.pr-0\.5 {
    padding-right: 2px
}

.mt-1 {
    margin-top: 4px
}

.pt-1 {
    padding-top: 4px
}

.mb-1 {
    margin-bottom: 4px
}

.pb-1 {
    padding-bottom: 4px
}

.ml-1 {
    margin-left: 4px
}

.pl-1 {
    padding-left: 4px
}

.mr-1 {
    margin-right: 4px
}

.pr-1 {
    padding-right: 4px
}

.mt-2 {
    margin-top: 8px
}

.pt-2 {
    padding-top: 8px
}

.mb-2 {
    margin-bottom: 8px
}

.pb-2 {
    padding-bottom: 8px
}

.ml-2 {
    margin-left: 8px
}

.pl-2 {
    padding-left: 8px
}

.mr-2 {
    margin-right: 8px
}

.pr-2 {
    padding-right: 8px
}

.mt-3 {
    margin-top: 12px
}

.pt-3 {
    padding-top: 12px
}

.mb-3 {
    margin-bottom: 12px
}

.pb-3 {
    padding-bottom: 12px
}

.ml-3 {
    margin-left: 12px
}

.pl-3 {
    padding-left: 12px
}

.mr-3 {
    margin-right: 12px
}

.pr-3 {
    padding-right: 12px
}

.mt-4 {
    margin-top: 16px
}

.pt-4 {
    padding-top: 16px
}

.mb-4 {
    margin-bottom: 16px
}

.pb-4 {
    padding-bottom: 16px
}

.ml-4 {
    margin-left: 16px
}

.pl-4 {
    padding-left: 16px
}

.mr-4 {
    margin-right: 16px
}

.pr-4 {
    padding-right: 16px
}

.mt-5 {
    margin-top: 20px
}

.pt-5 {
    padding-top: 20px
}

.mb-5 {
    margin-bottom: 20px
}

.pb-5 {
    padding-bottom: 20px
}

.ml-5 {
    margin-left: 20px
}

.pl-5 {
    padding-left: 20px
}

.mr-5 {
    margin-right: 20px
}

.pr-5 {
    padding-right: 20px
}

.mt-6 {
    margin-top: 24px
}

.pt-6 {
    padding-top: 24px
}

.mb-6 {
    margin-bottom: 24px
}

.pb-6 {
    padding-bottom: 24px
}

.ml-6 {
    margin-left: 24px
}

.pl-6 {
    padding-left: 24px
}

.mr-6 {
    margin-right: 24px
}

.pr-6 {
    padding-right: 24px
}

.mt-8 {
    margin-top: 32px
}

.pt-8 {
    padding-top: 32px
}

.mb-8 {
    margin-bottom: 32px
}

.pb-8 {
    padding-bottom: 32px
}

.ml-8 {
    margin-left: 32px
}

.pl-8 {
    padding-left: 32px
}

.mr-8 {
    margin-right: 32px
}

.pr-8 {
    padding-right: 32px
}

.mt-10 {
    margin-top: 40px
}

.pt-10 {
    padding-top: 40px
}

.mb-10 {
    margin-bottom: 40px
}

.pb-10 {
    padding-bottom: 40px
}

.ml-10 {
    margin-left: 40px
}

.pl-10 {
    padding-left: 40px
}

.mr-10 {
    margin-right: 40px
}

.pr-10 {
    padding-right: 40px
}

.mt-12 {
    margin-top: 48px
}

.pt-12 {
    padding-top: 48px
}

.mb-12 {
    margin-bottom: 48px
}

.pb-12 {
    padding-bottom: 48px
}

.ml-12 {
    margin-left: 48px
}

.pl-12 {
    padding-left: 48px
}

.mr-12 {
    margin-right: 48px
}

.pr-12 {
    padding-right: 48px
}

.mt-16 {
    margin-top: 64px
}

.pt-16 {
    padding-top: 64px
}

.mb-16 {
    margin-bottom: 64px
}

.pb-16 {
    padding-bottom: 64px
}

.ml-16 {
    margin-left: 64px
}

.pl-16 {
    padding-left: 64px
}

.mr-16 {
    margin-right: 64px
}

.pr-16 {
    padding-right: 64px
}

.mt-20 {
    margin-top: 80px
}

.pt-20 {
    padding-top: 80px
}

.mb-20 {
    margin-bottom: 80px
}

.pb-20 {
    padding-bottom: 80px
}

.ml-20 {
    margin-left: 80px
}

.pl-20 {
    padding-left: 80px
}

.mr-20 {
    margin-right: 80px
}

.pr-20 {
    padding-right: 80px
}

/* The root of the content page*/
.fleet-page-root {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0px;
}

.fleet-page-status-bar {
    color: var(--gray-800);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 12px 24px;
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.fleet-page-status-space {
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-width: 14px;
}

.docs-page {
    align-items: stretch;
    display: flex;
    height: calc(100% - var(--header-height));
    position: relative
}

.docs-page details {
    padding-left: 28px;
    position: relative
}

.docs-page details summary {
    cursor: pointer;
    list-style: none;
    padding-bottom: 8px
}

.docs-page details summary::-webkit-details-marker,.docs-page details summary::marker {
    display: none
}

.docs-page details:before {
    background-image: url(https://cdn.openai.com/API/images/summary_arrow_icon.svg);
    content: " ";
    height: 12px;
    left: 8px;
    position: absolute;
    top: 5px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    width: 12px
}

.docs-page details[open]:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.docs-nav {
    border-right: 1px solid var(--gray-100);
    flex: 0 0 auto;
    overflow: auto;
    padding: 20px 12px;
    width: 250px
}

@media(max-width: 800px) {
    .docs-nav {
        display:none
    }
}

.docs-body {
    flex: 1 1 auto;
    height: 100%;
    max-width: 100%;
    min-width: 0;
    overflow: auto
}

.docs-body .markdown-page {
    max-width: 100%;
    padding: var(--content-v-padding) var(--content-h-padding);
    width: calc(var(--content-width) + var(--content-h-padding)*2)
}

@media(min-width: 1372px) {
    .docs-body .markdown-page {
        margin-left:calc(50vw - 686px)
    }
}

.docs-introduction-page.markdown-content .intro-toc {
    grid-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr
}

.docs-introduction-page.markdown-content .intro-toc a {
    font-size: 18px;
    font-weight: 700
}

@media(max-width: 900px) {
    .docs-introduction-page.markdown-content .intro-toc {
        grid-template-columns:1fr 1fr
    }
}

.docs-embeddings img {
    height: auto;
    max-width: 100%
}

.docs-embeddings-types table {
    width: 100%
}

.docs-embeddings-types table td:first-child {
    width: 40%
}

.docs-embeddings-types table td:last-child {
    width: 60%
}

.docs-embeddings-sample-data-table {
    overflow-x: auto
}

.docs-embeddings-sample-data-table td {
    font-size: 14px
}

.docs-embeddings-sample-data-table td:nth-child(4),.docs-embeddings-sample-data-table td:nth-child(5) {
    min-width: 150px
}

.ft-guide-toc>ul {
    padding-left: 0
}

.ft-guide-toc>ul>li {
    list-style-type: none
}

.docs-samples-notice {
    position: relative
}

.docs-samples-notice ul {
    margin-bottom: 0
}

.docs-samples-notice .btn {
    bottom: 0;
    position: absolute;
    right: 0
}

.docs-responsive-button-list .btn {
    margin: 0 8px 8px 0!important
}

.markdown-page>.expn .tag-link {
    margin-bottom: 24px
}

.markdown-page>.expn .docs-tag-link-list {
    margin-bottom: 12px
}

.markdown-page>.expn .docs-tag-link-list .tag-link {
    margin-bottom: 12px;
    margin-right: 12px
}

.models-table {
    line-height: 1.5
}

.models-table tbody td:first-child {
    font-family: var(--monospace);
    white-space: nowrap
}

@media(min-width: 768px) {
    .models-table tbody td:last-child,.models-table tbody td:nth-last-child(2) {
        min-width:130px
    }
}

@media(max-width: 600px) {
    .models-table {
        font-size:14px
    }
}

@media(min-width: 1372px) {
    .model-index-table {
        width:calc(100vw - 506px)
    }
}

.model-index-table .mono {
    background: none;
    padding: 0
}

.footnotes {
    border-top: 1px solid #ececf1;
    font-size: 15px;
    margin-top: 32px
}

.footnotes ol>li:after {
    border-radius: 4px;
    font-family: var(--monospace);
    line-height: 17px
}

.sandbox-preview {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--app-header-bottom);
    margin-top: 24px
}

.sandbox-preview .sandbox-screenshot,.sandbox-preview .sandbox-screenshot-small {
    border-radius: 4px;
    margin-right: 32px;
    min-width: 250px;
    overflow: hidden
}

.sandbox-preview .sandbox-screenshot {
    flex: 2 1
}

.sandbox-preview .sandbox-screenshot-small {
    flex: .5 1
}

.sandbox-preview .preview-info {
    flex: 1 1;
    min-width: 200px;
    width: 100%
}

.sandbox-preview .preview-info .title {
    font-weight: 700;
    padding-bottom: 2px
}

.sandbox-preview .preview-info .description {
    color: var(--gray-600);
    margin-bottom: 12px
}

@media(max-width: 500px) {
    .sandbox-preview {
        flex-direction:column
    }

    .sandbox-preview .sandbox-screenshot,.sandbox-preview .sandbox-screenshot-small {
        margin-right: 0
    }
}

.docs-models-toc td:first-child {
    white-space: nowrap
}

.docs-mod-guide-banner p {
    margin: 0
}

.docs-mod-guide-banner code {
    font-size: .9em
}

.docs-edit-example {
    display: flex
}

.docs-edit-example>div {
    display: flex;
    flex: 1 1;
    flex-direction: column
}

.docs-edit-example>div:not(:last-child) {
    margin-right: 24px
}

.docs-edit-example .subheading {
    margin-bottom: 8px
}

.markdown-content.markdown-prompt-blockquote .docs-edit-example blockquote {
    font-size: 13px;
    line-height: 20px
}

.markdown-content.markdown-prompt-blockquote .docs-edit-example blockquote.docs-edit-example-instruction {
    height: 72px
}

.markdown-content.markdown-prompt-blockquote .docs-edit-example blockquote:not(.docs-edit-example-instruction) {
    flex: 1 1
}

.markdown-content.markdown-prompt-blockquote .docs-edit-example blockquote.no-input {
    color: var(--gray-400)
}

.docs-usage-tiers-table td:last-child {
    white-space: nowrap
}

.docs-404-feedback {
    color: var(--gray-500);
    font-size: 14px;
    margin-top: 24px
}

.images-examples table {
    width: 100%
}

.images-examples table img {
    height: auto;
    width: 200px
}

.images-examples td:not(:first-child) {
    min-width: 216px
}

.images-examples.flex-first-col td:first-child {
    width: 100%
}

.images-examples.flex-first-col td:last-child,.images-examples:not(.flex-first-col) td:first-child {
    min-width: 208px
}

.images-examples:not(.flex-first-col) td:last-child {
    width: 100%
}

.markdown-content p.images-edit-prompt {
    color: var(--gray-600);
    margin-top: -20px
}

a.side-nav-item {
    border-radius: 8px;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    padding-left: 30px;
    text-decoration: none
}

a.side-nav-item,a.side-nav-item:visited:not(:hover):not(.active) {
    color: var(--gray-600)
}

a.side-nav-item:hover:not(.active-exact) {
    background: var(--gray-50);
    color: var(--gray-800)
}

a.side-nav-item.active:not(.active-exact) {
    color: var(--gray-800);
    font-weight: 700
}

a.side-nav-item.active-exact {
    background-color: #e6f3f3;
    color: var(--primary);
    font-weight: 700
}

a.side-nav-child {
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px
}

.side-nav-section+.side-nav-section {
    margin-top: 30px
}

.side-nav-header {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0 12px
}

.subheading {
    color: #202123;
    color: var(--gray-900);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .08em;
    line-height: 16px;
    text-transform: uppercase
}

.chapter_title {
    color: #202123;
    color: var(--gray-900);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 20px;
    padding-top: 10px;
    
    /* text-transform: uppercase */
}

.chapter_title:hover {
    color: var(--primary);
}