/* css for notice */
.notice {
    border: 1px solid var(--gray-100);
    border-radius: 8px;
    flex: 0 0 auto;
    min-width: 275px;
    padding: 16px 18px;
    position: relative;
    text-align: center;
    width: 100%
}

@media(min-width: 768px) {
    .notice {
        align-items:center;
        display: inline-flex
    }
}

@media(max-width: 767px) {
    .notice {
        grid-gap:14px;
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: auto
    }

    .notice.has-icon {
        grid-template-columns: 20px auto
    }
}

.notice-primary {
    background-color: var(--green-50);
    border-color: var(--green-100)
}

.notice-neutral {
    background-color: var(--gray-50);
    border-color: var(--gray-100)
}

.notice-warning {
    background-color: rgba(244,172,54,.12);
    border-color: rgba(244,172,54,.24)
}

.notice-icon {
    font-size: 20px
}

@media(min-width: 768px) {
    .notice-icon {
        display:inline-flex;
        flex: 0 0 auto;
        margin-right: 14px
    }

    .has-body .notice-icon {
        align-self: flex-start
    }
}

.notice-icon-primary {
    color: var(--primary)
}

.notice-icon-neutral {
    color: var(--gray-600)
}

.notice-icon-warning {
    color: var(--icon-warning-color)
}

.notice-icon-danger {
    color: var(--red-500)
}

.notice-message {
    color: var(--gray-900);
    margin-bottom: -2px;
    text-align: left
}

.notice-message a:not(.btn):not(:visited),.notice-message a:not(.btn):visited {
    color: var(--primary);
    font-weight: 700;
    text-decoration: none
}

@media(min-width: 768px) {
    .notice-message {
        flex:1 1 auto
    }
}

.notice-body {
    margin-top: -2px
}

.notice-body a:not(.btn):not(:visited),.notice-body a:not(.btn):visited {
    font-weight: 400
}

div+.notice-body.body-small {
    margin-top: 4px
}

.notice-body>p:last-child {
    margin-bottom: 0
}

.notice-actions {
    align-items: center;
    display: flex;
    flex-wrap: nowrap
}

@media(min-width: 768px) {
    .notice-actions {
        margin-left:14px
    }
}

@media(max-width: 767px) {
    .has-icon .notice-actions {
        grid-column-start:2
    }
}

.notice-actions .btn {
    white-space: nowrap
}

.notice-dismiss {
    align-items: center;
    display: flex
}

.notice-dismiss .btn-neutral {
    background-color: unset;
    color: var(--gray-900);
    transition: color .2s
}

.notice-dismiss .btn-neutral:hover {
    background-color: unset;
    color: var(--gray-700)
}

.notice-dismiss .btn-neutral:active {
    background-color: unset;
    color: var(--gray-600)
}

.notice-dismiss .btn-neutral svg {
    color: var(--gray-600);
    font-size: 20px
}

@media(min-width: 768px) {
    .notice-dismiss {
        margin-left:8px
    }

    .notice-dismiss-mobile {
        display: none
    }
}

@media(max-width: 767px) {
    .notice-dismiss-desktop {
        display:none
    }
}

.notice-no-top-left-border {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none
}
