.element {
    flex: 1;
    font-family: var(--mainFont);
    margin: 0 10px; /* Adjust spacing between elements */
  }
  
  /* Optional: Add more styling as needed */
  .element h1 {
    font-family: var(--mainFont);
  }
  .element h2 {
    font-family: var(--mainFont);
  }
  .element p {
    margin-top: 0;
  }

  .blue-color-element {
    width: 400px;
    color: white;
    background-color: blue;
    border-radius: 10px; /* Adjust the value for corner roundness */
    padding: 10px;
    margin: 10px;
  }

  .green-color-element {
    width: 400px;
    color: white;
    background-color: darkgreen;
    border-radius: 10px; /* Adjust the value for corner roundness */
    padding: 10px;
    margin: 10px;
  }

  .gray-color-element {
    width: 400px;
    color: white;
    background-color: rgb(105, 107, 105);
    border-radius: 10px; /* Adjust the value for corner roundness */
    padding: 10px;
    margin: 10px;
  }

  .red-color-element {
    width: 400px;
    color: white;
    background-color: darkred;
    border-radius: 10px; /* Adjust the value for corner roundness */
    padding: 10px;
    margin: 10px;
  }

  .cyan-color-element {
    width: 400px;
    color: white;
    background-color: darkcyan;
    border-radius: 10px; /* Adjust the value for corner roundness */
    padding: 10px;
    margin: 10px;
  }

  .ovr-page .ovr-card {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 192px;
    justify-content: flex-end;
    overflow: hidden;
    padding: 20px 24px;
    position: relative;
}

a h2 {
  color: white; /* Replace 'black' with the desired color */
}

a p {
  color: white; /* Replace 'black' with the desired color */
}