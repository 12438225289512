.navbar.main-container {
    position: absolute;
    background-color: var(--bgColorDark);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
  }
  
  .navbar.menu-item-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .navbar.logo-img {
    height: auto;
    width: 32px;
  }
  
  .navbar.logo-version {
    color: gray;
    font-family: var(--fontFamily);
    font-size: small;
    font-weight: normal;
    justify-content: center;
    background-color: transparent;
    margin-left: 15px;
    margin-top: -10px;
    margin-bottom: -15px;
  }
  
  .navbar.tab-title {
    width: 100%;
    text-decoration: none;
    background-color: var(--bgColorDark);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .navbar.tab-title-hightlight {
    width: 100%;
    text-decoration: none;
    background-color: #3C4F57;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .navbar.tab-image {
    left: 15px;
    width: 40px;
    height: auto;
    background-color: transparent;
  }
  
  .navbar.tab-image:hover {
    filter: invert(68%) sepia(1%) saturate(0%) hue-rotate(171deg) brightness(91%) contrast(84%);
  }
  
  .navbar.text-title:hover {
    filter: invert(68%) sepia(1%) saturate(0%) hue-rotate(171deg) brightness(91%) contrast(84%);
  }
  
  .navbar.text-title {
    top: 2px;
    left: 20px;
    color: white;
    font-size: large;
    font-family: var(--fontFamily);
    background-color: transparent;
    transition: width 2s ease, transform 2s ease; /* transition property to animate transform changes */
    transform: translateX(-100%);
    display: none;
  }
  
  .navbar.text-title.expanded {
    width: 120px;
    transform: translateX(0%);
    display: flex;
  }
  
  .navbar.text-title-highlight {
    left: 10px;
    color: #00b454;
    font-size: large;
    font-family: var(--fontFamily);
    background-color: transparent;
  }
  
  .navbar.account-menu {
    margin-top: -50px;
    margin-right: 30px;
    z-index: 10000;
  }
  
  .navbar.account-name {
    color: white;
    font-size: large;
    font-family: var(--fontFamily);
    background-color: transparent;
    border: none;
    transition: width 2s ease, transform 2s ease;
    display: none;
  }
  
  .navbar.account-name.expanded {
    transform: translateX(0%);
    display: block;
  }
  
  .navbar.account-logo {
    height: auto;
    width: auto;
    background-color: transparent;
  }
  
  .dropdown-menu.show  {
    background-color: var(--bgColorLight);
  }
  a.dropdown-item{
    color:white
  }
  .dropdown-item:hover{
    color:black
  }
  