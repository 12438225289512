@import './GlobalDefine.css';

  /* For the right image */
  .image-container {
    flex: 1.618;
    position: relative;
  }

  .image-overlay-column {
    position: absolute;
    width: 100%;
    top: 80%;
    left: 40px;
    transform: translate(0%, -50%);
    display: flex;
    flex-direction: column;
  }

  .image-slogan-overlay {
    color: white;
    font-size: 48pt;
    margin-bottom: 20px;
    font-family: var(--mainFont);
    /* Add more styling as needed */
  }

  .image-overlay {
    color: white;
    font-size: 16pt;
    font-family: var(--mainFont);
    /* Add more styling as needed */
  }
  
  .image-background {
    width: 100%;
    height: 100%;
  }


  /* For the left container */
.container {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    padding: 20px;
  }
  
  .logo {
    width: 150px;
  }
  
  .title {
    font-family: var(--mainFont);
    margin-bottom: 30px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  
  .input-field {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: var(--borderRadius);
    border: 1px solid #ddd;
  }
  
  .submit-button {
    margin-top: 10px;
    height: 40px;
    padding: 10px;
    border-radius: var(--borderRadius);
    border: none;
    font-family: var(--mainFont);
    font-size: medium;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  /* For the input field of the sign in page*/
  .input-label {
    display: block;
    margin-bottom: 8px;
    font-size: medium;
    font-family: var(--mainFont);
    color: #333;
    font-weight: bold;
  }
  
  .input-field {
    width: 100%;
    margin-top: 10px;
    font-family: var(--mainFont);
    padding: 12px 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box; /* Makes sure padding doesn't affect overall width */
    font-size: medium;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 3px #007bff;
  }

  .error-message {
    height: 20px; /* Reserve space for the error message */
    margin-top: 10px;
    color: red;
    font-family: var(--mainFont);
    line-height: 20px; /* Aligns text vertically */
    text-align: center;
  }
  
  /* When there is no error message, keep the space but hide the text */
  .error-message:empty {
    visibility: hidden;
  }

  .forgot-password-link {
    text-align: center; /* Center-align the link */
    margin-top: 10px;   /* Add some margin for spacing */
  }
  
  .forgot-password-link span {
    color: blue;        /* Blue text color */
    cursor: pointer;    /* Display a pointer cursor when hovered */
    text-decoration: underline; /* Underline the text on hover */
  }